import { Box, Container, Typography } from '@material-ui/core'

import Page from '@components/Page.js'
import FunderTransferList from '@components/_dashboard/funders/FunderTransferList'

const FunderTransfersPage = () => {

  return <Page title="Funder Transfers | LMS">
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Funder Transfers</Typography>
      </Box>
      <FunderTransferList />
    </Container>
  </Page>
}

export default FunderTransfersPage
