import { Link as RouterLink } from 'react-router-dom';
// material
import { Link } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { fCurrency } from '../../../utils/formatNumber';
import { fDateTime } from '../../../utils/formatTime';
import {
  fetchTransactionFunderName,
  fetchTransactionProductName
} from '@utils/fetchTransactionsMetaData';
import {
  fundingSourceNameFromId
} from '@utils/mapping'

export default function AccountRow({ account }) {
  return (
    <TableRow key={account.file_number}>
      <TableCell component="th" scope="row">
        <Link
          to={`/dashboard/accounts/${account.file_number}`}
          underline="hover"
          component={RouterLink}
        >
          {account.file_number}
        </Link>
      </TableCell>
      <TableCell align="center">{fundingSourceNameFromId(account.funding_source)}</TableCell>
      <TableCell align="center">{fetchTransactionFunderName(account.funder)}</TableCell>
      <TableCell align="center">{fetchTransactionProductName(account.product)}</TableCell>
      <TableCell align="center">{(account.active_interest_rate * 100).toFixed(2)} %</TableCell>
      <TableCell align="center">{fCurrency(account.total_principal)}</TableCell>
      <TableCell align="center">{fCurrency(account.total_interest)}</TableCell>
      <TableCell align="center">{fCurrency(account.total_charge_fee)}</TableCell>
      <TableCell align="center">{fCurrency(account.total_outstanding)}</TableCell>
      <TableCell align="center">{account.parent_file_number}</TableCell>
      <TableCell align="center">{fDateTime(account.created_at)}</TableCell>
      <TableCell align="center">{fDateTime(account.updated_at)}</TableCell>
    </TableRow>
  );
}
