import { useState } from 'react'
import reject from 'lodash/reject'
import {
  Grid,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress
} from '@material-ui/core'

import * as funderTransferController from '@controllers/FunderTransferController'

const SellAccount = ({ account, onCancel }) => {
  const [ error, setError ] = useState()
  const [ config ] = useState(JSON.parse(localStorage.getItem('config')))
  const [ loading, setLoading ] = useState()
  const [ newFunder, setNewFunder ] = useState()
  const [ newFundingSource, setNewFundingSource ] = useState()

  const funders =
    reject(config.funders, f => f.id === account.funder )
      .map( ({ key, name }) => ({ id: key, name }))

  const fundingSources =  
    config.fundingSources
      .map( ({ key, institution }) => ({ id: key, institution }))

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel()
    }
  }

  const handleFunderChange = ({ target: { value }}) => {
    setNewFunder(value)
    setError()
  }

  const handleFundingSourceChange = ({ target: { value }}) => {
    setNewFundingSource(value)
    setError()
  }

  const handleSell = async () => {
    if (!(newFunder && newFundingSource)) {
      setError('Please select a funder and a funding source')
    }
    else {
      setLoading(true)
      setError()

      const payload = {
        previous_funder_key: `${config.funders.find(f => f.id === account.funder).key}`,
        current_funder_key: `${newFunder}`,
        product_key: `${config.products.find(p => p.id === account.product).key}`,
        loan_account_file_number: account.file_number,
        total_value: account.total_outstanding,
        funding_source_key: `${newFundingSource}`
      }

      const res = await funderTransferController.createFunderTransfer(payload)
      if (!res.ok) {
        setError(res.status)
      }
      else {
        const ft = await res.json()
        document.location = `/dashboard/funder-transfers/${ft.id}`
      }
      setLoading(false)
    }
  }

  return <Grid sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
    <FormControl>
      <InputLabel htmlFor="funder">New Funder</InputLabel>
      <Select
        label="Funder"
        labelId="funder-label"
        id="funder"
        onChange={handleFunderChange}
        sx={{ width: '160px' }}
        error={!!error}
        disabled={loading}
        value={newFunder}
      >
        {funders &&
          funders.map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>

    <FormControl>
      <InputLabel htmlFor="fundingSource">Funding Source</InputLabel>
      <Select
        label="Funding Source"
        labelId="funding-source-label"
        id="fundingSource"
        onChange={handleFundingSourceChange}
        sx={{ width: '160px' }}
        error={!!error}
        disabled={loading}
        value={newFundingSource}
      >
        {
          fundingSources && 
            fundingSources.map((value, index) => (
              <MenuItem key={`S` + index} value={value.id}>
                {value.institution}
              </MenuItem>
            ))
        }
      </Select>
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>

    {!loading && <>
      <Button color="primary" onClick={handleSell}
        sx={{ ml: 3}}>
        Sell
      </Button>

      <Button variant="outlined" color="secondary" onClick={handleCancel}
        sx={{ ml: 3}}>
        Cancel
      </Button>
    </>}

    {loading && <CircularProgress />}
  </Grid>
}

export default SellAccount
