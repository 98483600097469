import {
  transactionsPath,
  singleTransactionPath,
  reverseTransactionPath,
  postTransactionPath,
  getHeaders
} from '../utils/constant';

export async function getTransaction(transactionId) {
  const output = await fetch(singleTransactionPath(transactionId), {
    headers: getHeaders(),
    credentials: 'include'
  })
    .then((response) => {
      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(response.status, response.body);
      }
      return response.json();
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occurred', e);
      return { success: false };
    });
  return output;
}

export async function createTransaction(data) {
  // eslint-disable-next-line prefer-const
  let dataToSend = {
    file_number: data.accountFileNumber,
    effective_date: data.effectiveDate,
    type: data.type,
    debit: data.debit,
    funder_id: `${data.funder}` || undefined,
    product_id: `${data.product}` || undefined,
    funding_source_id: `${data.fundingSource}` || undefined
  };
  if (data.amount > 0) {
    dataToSend.total_amount = data.amount;
  }
  if (data.interestRate > 0) {
    dataToSend.interest_rate = data.interestRate;
  }
  if (data.parentFileNumber !== '') {
    dataToSend.parent_file_number = data.parentFileNumber;
  }
  if ([11, 12].indexOf(data.type) > -1) {
    dataToSend.prepayment_fee_discount = data.prepaymentDiscountPercentage;
  }
  if (data.liquidationReason > 0) {
    dataToSend.liquidation_reason = data.liquidationReason;
  }
  if (data.payoutReason > 0) {
    dataToSend.payout_reason = data.payoutReason;
  }

  const output = await fetch(transactionsPath(), {
    method: 'POST',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include',
    body: JSON.stringify(dataToSend)
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      if (response.status === 201) {
        return response.json();
      }
      if (response.status === 401) {
        throw new Error({ code: 401, msg: 'Wrong username or password' });
      } else if (response.status === 400) {
        throw new Error({
          code: 400,
          msg: 'Unknown error, contact support for help. \nError code: 400'
        });
      }
    })
    .then((data) =>
      // console.log('DATA', data);
      ({ success: true, data })
    )
    .catch((e) => {
      console.error('exception occured', e);
      return e.json().then((error) => ({ success: false, errors: error }));
    });
  return output;
}

export async function postTransaction(transactionId) {
  const output = await fetch(postTransactionPath(transactionId), {
    method: 'PATCH',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      if (response.status === 401) {
        throw new Error({ code: 401, msg: 'Wrong username or password' });
      } else if (response.status === 400) {
        throw new Error({
          code: 400,
          msg: 'Unknown error, contact support for help. \nError code: 400'
        });
      }
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occured', e);
      return { success: false };
    });

  return output;
}

export async function deleteTransaction(transactionId) {
  const output = await fetch(singleTransactionPath(transactionId), {
    method: 'DELETE',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 204) {
        return {};
      }
      if (response.status === 401) {
        throw new Error({ code: 401, msg: 'Wrong username or password' });
      } else if (response.status === 400) {
        throw new Error({
          code: 400,
          msg: 'Unknown error, contact support for help. \nError code: 400'
        });
      }
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occured', e);
      return { success: false };
    });

  return output;
}

export async function reverseTransaction(transactionId) {
  const output = await fetch(reverseTransactionPath(transactionId), {
    method: 'PATCH',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      if (response.status === 401) {
        throw new Error({ code: 401, msg: 'Wrong username or password' });
      } else if (response.status === 400) {
        throw new Error({
          code: 400,
          msg: 'Unknown error, contact support for help. \nError code: 400'
        });
      }
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occured', e);
      return { success: false };
    });

  return output;
}
