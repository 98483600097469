import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

// material
import { Box, Grid, Container, Typography, CircularProgress } from '@material-ui/core'
import { useGridApiRef } from '@mui/x-data-grid'
// components
import Page from '../components/Page'
import { AccountInformation } from '../components/_dashboard/account'
import { TransactionsList } from '../components/_dashboard/transactions'

import {
  singleAccountPath,
  relatedTransactionsPath,
  relatedPendingTransactionsPath,
  getHeaders,
  handleUnauthorized
} from '../utils/constant'

export default function Account() {
  const [notFound, setNotFound] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [account, setAccount] = React.useState({})
  const [transactionsInfo, setTransactionsInfo] = React.useState([])
  const [loadingTransactions, setLoadingTransactions] = React.useState(true)
  const [filter, setFilter] = React.useState('All')
  const [params] = React.useState(useParams())
  
  const apiRef = useGridApiRef()
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0
  })

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const previousFilter = usePrevious(filter)

  const API_PATH =
    filter === 'All'
      ? relatedTransactionsPath(params.id, paginationModel.page + 1)
      : relatedPendingTransactionsPath(params.id, paginationModel.page + 1)

  const filterTransactions = (e) => {
    setFilter(e.currentTarget.textContent)
  }

  useEffect(() => {
    fetch(singleAccountPath(params.id), {
      headers: getHeaders(),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .then((data) => {
        setAccount(data)
        setLoading(false)
      })
      .catch((error) => {
        if (error.message === '401') {
          handleUnauthorized()
          console.error('ERROR: ', error)
        }
        if (error.message === '404') {
          setNotFound(true)
        }
      })
  }, [])

  useEffect(() => {
    if (previousFilter !== filter && paginationModel.page === 0) {
      fetch(API_PATH, {
        headers: getHeaders(),
        credentials: 'include'
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.status)
          }
          return response.json()
        })
        .then((data) => {
          setTransactionsInfo(data)
          setLoadingTransactions(false)
          apiRef.current.setRowCount(data.count)
        })
        .catch((error) => {
          if (error.message === '401') {
            handleUnauthorized()
            console.error('ERROR: ', error)
          }
          if (error.message === '404') {
            setNotFound(true)
            setTransactionsInfo({ results: [], count: 0 })
            setPaginationModel({
              pageSize: 50,
              page: 0
            })
          }
        })
    } else {
      setPaginationModel({
        pageSize: 50,
        page: 0
      })
    }
  }, [filter])

  useEffect(() => {
    fetch(API_PATH, {
      headers: getHeaders(),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .then((data) => {
        setTransactionsInfo(data)
        setLoadingTransactions(false)
      })
      .catch((error) => {
        if (error.message === '401') {
          handleUnauthorized()
          console.error('ERROR: ', error)
        }
        if (error.message === '404') {
          setNotFound(true)
          setTransactionsInfo({ results: [], count: 0 })
        }
      })
  }, [account, paginationModel])

  return (
    <Page title="Account | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Account</Typography>
        </Box>
        <Grid container spacing={3}>
          {notFound ? (
            <Grid item xs={12}>
              Invalid Account Id
            </Grid>
          ) : (
            <Grid item xs={12}>
              {loading ? <CircularProgress /> : <AccountInformation account={account} />}
            </Grid>
          )}
        </Grid>
        <Box sx={{ pb: 5, pt: 5 }}>
          <Typography variant="h4">Transactions</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loadingTransactions ? (
              <CircularProgress />
            ) : (
              <TransactionsList
                disableFilter
                transactions={transactionsInfo.results}
                currentFilter={filter}
                onFilterClicked={filterTransactions}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                apiRef={apiRef}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
