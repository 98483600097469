import React, { useEffect } from 'react';

// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  TotalInterest,
  TotalPremium,
  TotalChargeFees,
  TotalPrincipalAmount
} from '../components/_dashboard/app';
import { portfolioBalances, getConfig, getHeaders, handleUnauthorized } from '../utils/constant';
import { getAllFunders } from '@controllers/FunderController'
import { getAllProducts } from '@controllers/ProductController'
import { getAllFundingSources } from '@controllers/FundingSourceController';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [principalBalance, setPrincipalBalance] = React.useState(0);
  const [interest, setInterest] = React.useState(0);
  const [chargeFee, setChargeFee] = React.useState(0);
  const [premium, setPremium] = React.useState(0);

  useEffect(() => {
    fetch(portfolioBalances(), {
      credentials: 'include',
      mode: 'cors',
      headers: getHeaders()
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        setPrincipalBalance(data.principal);
        setInterest(data.interest);
        setChargeFee(data.charge_fee);
        setPremium(data.premium);
      })
      .catch((error) => {
        if (error.message === '401') {
          handleUnauthorized();
          console.error('ERROR: ', error);
        }
        if (error.message === '404') {
          console.log('error 404');
        }
      });
  }, []);

  const configProducts = async () => {
    const res = await getAllProducts()

    if (!res.ok) {
      throw new Error(res.status)
    }
    else {
      const { results } = await res.json()
      const products = results.map(({ id, name, key }) => ({ id, name, key }))

      const currentConfig = JSON.parse(localStorage.getItem('config'))
      const config = { ...currentConfig, products }
      localStorage.setItem('config', JSON.stringify(config));
    }
  }

  const configFunders = async () => {
    const res = await getAllFunders()

    if (!res.ok) {
      throw new Error(res.status)
    }
    else {
      const { results: funders } = await res.json()
      // const funders = results.map(({ id, name, key }) => ({ id, name, key }))

      const currentConfig = JSON.parse(localStorage.getItem('config'))
      const config = { ...currentConfig, funders }
      localStorage.setItem('config', JSON.stringify(config));
    }
  }

  const configFundingSources = async () => {
    const res = await getAllFundingSources()

    if (!res.ok) {
      throw new Error(res.status)
    }
    else {
      const { results: fundingSources } = await res.json()

      const currentConfig = JSON.parse(localStorage.getItem('config'))
      const config = { ...currentConfig, fundingSources }
      localStorage.setItem('config', JSON.stringify(config));
    }
  }

  useEffect(() => {
    fetch(getConfig(), {
      headers: getHeaders(),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        const transactionCategories = data.transaction_categories;
        const liquidationReasons = data.liquidation_reasons;
        const payoutReasons = data.payout_reasons;
        const transactionTypes = [];

        if (transactionCategories.length > 0) {
          transactionCategories.forEach((category) => {
            transactionTypes.push(...category.transaction_types);
          });
        }

        localStorage.setItem('types', JSON.stringify(transactionTypes));
        localStorage.setItem('liquidationReasons', JSON.stringify(liquidationReasons));
        localStorage.setItem('payoutReasons', JSON.stringify(payoutReasons));
        localStorage.setItem('config', JSON.stringify(data));

        configFunders()
        configProducts()
        configFundingSources()
      })
      .catch((error) => {
        if (error.message === '401') {
          handleUnauthorized();
          console.error('ERROR: ', error);
        }
        if (error.message === '404') {
          console.log('error 404');
        }
      });
  }, []);

  return (
    <Page title="Dashboard | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TotalPrincipalAmount total={principalBalance} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalInterest total={interest} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalChargeFees total={chargeFee} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TotalPremium total={premium} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
