import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'

const Row = ({ fundingSource }) => (
  <TableRow key={`fundingSource-${fundingSource.key}`}>
    <TableCell component="th" scope="row">
      <Link
        to={`/dashboard/funding-sources/${fundingSource.id}`}
        underline="hover"
        component={RouterLink}
      >
        {fundingSource.key}
      </Link>
    </TableCell>
    <TableCell align="center">{fundingSource.institution}</TableCell>
    <TableCell align="center">{fundingSource.type_of_capital}</TableCell>
    <TableCell align="center">
      {fundingSource.institution}-{fundingSource.type_of_capital}
    </TableCell>
  </TableRow>
)

export default function FundingSourcesList({ fundingSources }) {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="all funding sources table">
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell align="center">Institution</TableCell>
              <TableCell align="center">Type of Capital</TableCell>
              <TableCell align="center">Identifier</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fundingSources.map((fundingSource) => (
              <Row fundingSource={fundingSource} key={fundingSource.key} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
