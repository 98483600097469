import { singleAccountPath, updateAccountInterestRatePath, getHeaders } from '../utils/constant';

export async function getAccount(accountId) {
  const output = await fetch(singleAccountPath(accountId), {
    headers: getHeaders(),
    credentials: 'include'
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occurred', e);
      return { success: false };
    });
  return output;
}

export async function updateAccountInterestRate(data) {
  const output = await fetch(updateAccountInterestRatePath(), {
    method: 'POST',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include',
    body: JSON.stringify({
      effective_date: data.effectiveDate,
      interest_rate: data.interestRate,
      account: data.accountId
    })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .then((data) => ({ success: true, data }))
    .catch((e) => {
      console.error('exception occurred', e);
      return { success: false };
    });
  return output;
}
