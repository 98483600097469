import { Grid } from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'

const RootStyledDiv = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  WebkitBoxPack: 'justify',
  borderTop: '1px gray solid',
  borderBottom: '1px gray solid'
})

const LabelDiv = styled('div')({
  color: 'rgb(62, 62, 60)',
  fontSize: '14px'
})

const DataDiv = styled('div')({
  color: 'rgb(8, 7, 7)',
  fontSize: '16px'
})

const Field = ({ label, value, formatter = (v) => v }) => {
  return (
    <Grid item xs={6} textAlign="center">
      <RootStyledDiv>
        <LabelDiv>{label}</LabelDiv>
        <DataDiv>{formatter(value)}</DataDiv>
      </RootStyledDiv>
    </Grid>
  )
}

const FundingSourceDetails = ({ fundingSource }) => {
  return (
    <Grid container spacing={3}>
      <Field label="Key" value={fundingSource.key} />
      <Field
        label="Identifier"
        value={`${fundingSource.institution}-${fundingSource.type_of_capital}`}
      />
      <Field label="Institution" value={fundingSource.institution} />
      <Field label="Type of Capital" value={fundingSource.type_of_capital} />
    </Grid>
  )
}

export default FundingSourceDetails
