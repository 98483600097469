import React from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { postTransaction } from '../../../controllers/Transaction';

export default function PostTransaction({ transactionId }) {
  const [open, setOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [loading, setLoading] = React.useState(false); 
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e.target.textContent === 'Yes') {
      setLoading(true)
      postTransaction(transactionId).then((response) => {
        if (response.success) {
          setOpen(false);
          navigate(0);
        } else {
          setErrorText('Unable to reverse the transaction');
        }
        setLoading(false);
      });
    } else {
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Post Transaction
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Post Transaction</DialogTitle>
        <DialogContent>
          {errorText !== '' && <Alert severity="error">{errorText}</Alert>}
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to post this transaction?
          </DialogContentText>
          {
            loading && <LinearProgress />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button disabled={loading} onClick={handleClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
