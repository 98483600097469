import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import { CircularProgress, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@material-ui/core'

import { funderNameFromKey, productNameFromKey, fundingSourceNameFromKey } from '@utils/mapping'
import { fDate } from '@utils/formatTime'
import { PagedDataProvider, usePagedData } from '@controllers/PagedDataProvider'
import { fCurrency } from '@utils/formatNumber'
import { funderTransferListPath } from '@utils/constant'
import { populatePurchasePrice } from '@controllers/FunderTransferController'


const FunderTransferRow = ({funderTransfer}) => {
  populatePurchasePrice(funderTransfer)
  return <TableRow>
    <TableCell component="th" scope="row">
      <Link
        to={`/dashboard/funder-transfers/${funderTransfer.id}`}
        underline="hover"
        component={RouterLink}
      >
        {funderTransfer.id}
      </Link>
    </TableCell>
    <TableCell align="center">{funderNameFromKey(funderTransfer.previous_funder_key)}</TableCell>
    <TableCell align="center">{funderNameFromKey(funderTransfer.current_funder_key)}</TableCell>
    <TableCell align="center">{productNameFromKey(funderTransfer.product_key)}</TableCell>
    <TableCell align="center">{funderTransfer.funding_source_key && fundingSourceNameFromKey(funderTransfer.funding_source_key)}</TableCell>
    <TableCell align="center">
      <Link
          to={`/dashboard/accounts/${funderTransfer.loan_account_file_number}`}
          underline="hover"
          component={RouterLink}
        >
          {funderTransfer.loan_account_file_number}
      </Link>
    </TableCell>
    <TableCell align="center">{funderTransfer.created_by}</TableCell>
    <TableCell align="center">{fDate(funderTransfer.created_at)}</TableCell>
    <TableCell align="center">{fDate(funderTransfer.updated_at)}</TableCell>
    <TableCell align="center">{fCurrency(funderTransfer.total_value)}</TableCell>
    <TableCell align="center">{fCurrency(funderTransfer.purchase_price)}</TableCell>
    <TableCell align="center">{fDate(funderTransfer.posted_at)}</TableCell>
  </TableRow>
}

const Pagination = () => {
  const {
    count, pageNumber, rowsPerPage,
    handlePageChange, handleRowsPerPageChange
  } = usePagedData()

  return <TablePagination
    component="div"
    count={count}
    rowsPerPageOptions={[5, 10, 25, 50]}
    rowsPerPage={rowsPerPage}
    page={pageNumber -1}
    onPageChange={handlePageChange}
    onRowsPerPageChange={handleRowsPerPageChange}
  />
}

const FunderTransferList = () => {
  const { loading, error, data } = usePagedData()
  return <Box>
    <Pagination />
    <TableContainer component={Paper}>
      <Table aria-label="all funder transfers table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="center">Previous Funder</TableCell>
            <TableCell align="center">New Funder</TableCell>
            <TableCell align="center">Product</TableCell>
            <TableCell align="center">Funding Source</TableCell>
            <TableCell align="center">Account File Number</TableCell>
            <TableCell align="center">Created By</TableCell>
            <TableCell align="center">Created Date</TableCell>
            <TableCell align="center">Last Updated Date</TableCell>
            <TableCell align="center">Total Value</TableCell>
            <TableCell align="center">Purchase Price</TableCell>
            <TableCell align="center">Posted At</TableCell>
          </TableRow>
        </TableHead>
        {loading && <CircularProgress />}
        {error &&
          <Typography variant="error-text">Error: {error}</Typography>
        }

        {!loading && data && <TableBody>
          {data.map((ft) => (
            <FunderTransferRow funderTransfer={ft} key={`ft-${ft.id}`} />
          ))}
        </TableBody> }
      </Table>
    </TableContainer>
    <Pagination />
  </Box>
}

const FunderTransferListWithData = () =>
  <PagedDataProvider endpoint={funderTransferListPath}>
    <FunderTransferList />
  </PagedDataProvider>

export default FunderTransferListWithData
