import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserInfo, getCSRFToken } from '../controllers/Authorizer';
import { servicesPortalEndpoint } from '../utils/constant';

export default function Profile() {
  const navigate = useNavigate();
  const [showSuccess] = useState(false);

  useEffect(() => {
    getUserInfo().then((data) => {
      if (data) {
        console.log('lms: ', data);
        getCSRFToken().then(() => {
          console.log('token set');
          navigate('/dashboard', { replace: true });
        });
      } else {
        window.location.assign(servicesPortalEndpoint());
      }
    });
  }, [showSuccess]);

  return <h3>Logging in LMS</h3>;
}
