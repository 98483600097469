import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material-ui
import {
  Alert,
  Stack,
  TextField,
  CircularProgress,
  Table,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import DatePicker from 'react-datepicker';
import { getAccount, updateAccountInterestRate } from '../controllers/Account';

export default function AccountInterestRate() {
  const [params] = useState(useParams());
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [interestRates, setInterestRates] = useState([]);

  useEffect(() => {
    getAccount(params.id).then((response) => {
      if (response.success) {
        setInterestRates(response.data.interest_rates);
        setFieldValue('accountId', response.data.id);
        setLoading(false);
      } else {
        setNotFound(true);
      }
    });
  }, [showSuccess]);

  const UpdateInterestRateSchema = Yup.object().shape({
    accountId: Yup.string().required('Account file number is reuqired'),
    interestRate: Yup.number().min(0).max(1).required('Interest Rate is required'),
    effectiveDate: Yup.string().required('Effective Date is required')
  });

  const formik = useFormik({
    initialValues: {
      accountId: '',
      interestRate: '',
      effectiveDate: ''
    },
    validationSchema: UpdateInterestRateSchema,
    onSubmit: () => {
      console.log(formik.values);
      updateAccountInterestRate(formik.values).then((response) => {
        if (response.success) {
          setShowError(false);
          setShowSuccess(true);
          formik.setSubmitting(false);
        } else {
          setShowError(true);
          formik.setSubmitting(false);
        }
      });
    }
  });

  const { errors, touched, values, isSubmitting, setFieldValue, handleSubmit, getFieldProps } =
    formik;

  return (
    <>
      <FormikProvider value={formik}>
        {showError && (
          <Alert severity="error">Unable to update interest rate. Please try again!</Alert>
        )}
        {showSuccess && <Alert severity="success">Interest rate saved sucessfully.</Alert>}
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ my: 2 }}>
            <TextField
              fullWidth
              autoComplete="fileNumber"
              type="text"
              disabled
              label="Account Id"
              {...getFieldProps('accountId')}
              error={Boolean(touched.accountId && errors.accountId)}
              helperText={touched.accountId && errors.accountId}
            />
            <TextField
              fullWidth
              autoComplete="fileNumber"
              type="number"
              label="Interest Rate"
              {...getFieldProps('interestRate')}
              error={Boolean(touched.interestRate && errors.interestRate)}
              helperText={touched.interestRate && errors.interestRate}
            />
            <DatePicker
              {...getFieldProps('effectiveDate')}
              dateFormat="yyyy-MM-dd"
              selected={(values.effectiveDate && new Date(values.effectiveDate)) || null}
              onChange={(val) => {
                setFieldValue(
                  'effectiveDate',
                  `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
                );
              }}
              customInput={
                <TextField
                  label="Effective Date"
                  error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                  helperText={touched.effectiveDate && errors.effectiveDate}
                />
              }
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Create Interest Rate Record
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="all interest rates table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Interest Rate</TableCell>
                <TableCell align="center">Effective Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interestRates &&
                interestRates.map((interest, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{interest.id}</TableCell>
                    <TableCell align="center">
                      {(interest.interest_rate * 100).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{interest.effective_date}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        // 'Loadded'
      )}
    </>
  );
}
