import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import DashboardApp from './pages/DashboardApp';

import Reports from './pages/Reports';
import Accounts from './pages/Accounts';
import Account from './pages/Account';
import Transactions from './pages/Transactions';
import Transaction from './pages/Transaction';
import CreateTransaction from './pages/CreateTransaction';
import AccountInterestRate from './pages/AccountInterestRates';
import Profile from './pages/Profile';
import Funders from './pages/Funders';
import Funder from './pages/Funder';
import SellAccount from './pages/SellAccount';
import FunderTransfer from './pages/FunderTransfer'
import FunderTransfers from './pages/FunderTransfers'
import FundingSourcesPage from 'pages/FundingSources';

// to be used for local testing
import { LoginForm } from './components/authentication/login';
import FundingSource from 'pages/FundingSource';

// ----------------------------------------------------------------------

const { REACT_APP_ENV: env } = process.env;

console.log(' --------------> ' + env);

function Router() {
  const rootRoute = env === 'local'
    ? { path: '/', element: <LoginForm /> }
    :  { path: '/', element: <Profile /> };
  return useRoutes([
    rootRoute,
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <DashboardApp /> },
        { path: 'accounts/:id', element: <Account /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'sell-account/:id', element: <SellAccount /> },
        { path: 'transactions/:id', element: <Transaction /> },
        { path: 'transactions', element: <Transactions /> },
        { path: 'create', element: <CreateTransaction /> },
        { path: 'funders/:id', element: <Funder /> },
        { path: 'funders', element: <Funders /> },
        { path: 'funder-transfers/:id', element: <FunderTransfer /> },
        { path: 'funder-transfers', element: <FunderTransfers /> },
        { path: 'funding-sources', element: <FundingSourcesPage /> },
        { path: 'funding-sources/:id', element: <FundingSource /> },
        { path: 'interest-rates/:id', element: <AccountInterestRate /> },
        { path: 'reports', element: <Reports /> }
      ]
    },
    // {
    //   path: '/',
    //   element: isLoggedIn ? <Navigate to="/dashboard" /> : <LogoOnlyLayout />,
    //   children: [
    //     { path: 'login', element: <Login /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '/', element: <Navigate to="/login" /> },
    //     { path: '*', element: <Navigate to="/404" /> }
    //   ]
    // },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
