import { oauthLoginPath, userInfoPath, fetchCSRFToken } from '../utils/constant';

export async function authorizeUser(username, password) {
  const output = await fetch(oauthLoginPath(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      username,
      password
    })
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      if (response.status === 401) {
        throw new Error({ code: 401, msg: 'Wrong username or password' });
      } else if (response.status === 400) {
        throw new Error({
          code: 400,
          msg: 'Unknown error, contact support for help. \nError code: 400'
        });
      }
    })
    .then((data) => {
      localStorage.setItem('name', data.name);
      localStorage.setItem('token', data.access);
      localStorage.setItem('refresh', data.refresh);
      return true;
    })
    .catch((e) => {
      console.error('unable to login', e);
      return false;
    });

  return output;
}

export function getUserInfo() {
  const output = fetch(userInfoPath(), {
    method: 'GET',
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw new Error({ code: response.status, msg: 'Session Invalid' });
    })
    .then((data) => {
      localStorage.setItem('name', data.name);
      return true;
    })
    .catch((e) => {
      console.error('unable to fetch user info', e);
      return false;
    });

  return output;
}

export async function getCSRFToken() {
  const output = await fetch(fetchCSRFToken(), {
    method: 'GET',
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        const output = response.json();
        return output;
      }
      throw new Error({ code: response.status, msg: 'Session Invalid' });
    })
    .then((data) => {
      console.log('Response data: ', data);
      localStorage.setItem('csrftoken', data.csrftoken);
      return true;
    })
    .catch((e) => {
      console.error('unable to fetch token', e);
      return false;
    });

  return output;
}
