import {
  funderTransferListPath,
  funderTransferCreatePath,
  funderTransferPath,
  funderTransferPostPath,
  getHeaders
} from '@utils/constant'
import Decimal from 'decimal.js'

const standardOptions = {
  headers: getHeaders(),
  credentials: 'include'
}

export const getAllFunderTransfers = async () =>
  await fetch(funderTransferListPath(), standardOptions)

export const getFunderTransfer = async (id) =>
  await fetch(funderTransferPath(id), standardOptions)

export const createFunderTransfer = async (body) =>
  await fetch(funderTransferCreatePath(), {
    method: 'POST',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include',
    body: JSON.stringify(body)
  })

export const postFunderTransfer = async (id) =>
  await fetch(funderTransferPostPath(id), {
    method: 'PATCH',
    headers: {
      ...getHeaders(),
      'X-CSRFToken': localStorage.getItem('csrftoken')
    },
    credentials: 'include'
  })

export const populatePurchasePrice = (ft) => {
  const { funders } = JSON.parse(localStorage.getItem('config'))
  const { purchase_premium: purchasePremium } = funders.find(f => f.key === ft.current_funder_key)

  const total = Decimal(ft.total_value)
  const rate = Decimal(purchasePremium).plus(1)

  ft.purchase_price = total.times(rate)
}
