import { Box, Grid, Container, Typography } from '@material-ui/core';

import Page from '../components/Page';
import { AccountsList } from '../components/_dashboard/accounts';


export default function Accounts() {
  return (
    <Page title="Accounts | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Accounts</Typography>
        </Box>
        <Grid container spacing={3}>
          <AccountsList />
        </Grid>
      </Container>
    </Page>
  );
}
