import {
  fundingSourceListPath,
  fundingSourcePath,
  getHeaders
} from '@utils/constant'

const standardOptions = {
  headers: getHeaders(),
  credentials: 'include'
}

export const getAllFundingSources = async () =>
  await fetch(fundingSourceListPath(), standardOptions)

export const getFundingSource = async (id) =>
  await fetch(fundingSourcePath(id), standardOptions)
