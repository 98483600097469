export function fetchTransactionType(index) {
  const transactionTypes = JSON.parse(localStorage.getItem('types'));
  return transactionTypes.find(tt => (tt.id === index)).name || ''
}

export function fetchTransactionPayoutReason(index) {
  const payoutReasons = JSON.parse(localStorage.getItem('payoutReasons'));
  return payoutReasons[index - 1].name;
}

export function fetchTransactionLiquidationReason(index) {
  const liquidationReasons = JSON.parse(localStorage.getItem('liquidationReasons'));
  return liquidationReasons[index - 1].name;
}

export function fetchTransactionFunderName(funderId) {
  const { funders } = JSON.parse(localStorage.getItem('config'))
  const found = funders.find(({ id }) => id === funderId)
  return found?.name ?? ''
}

export function fetchTransactionProductName(productId) {
  const { products } = JSON.parse(localStorage.getItem('config'))
  const found = products.find(({ id }) => id === productId)
  return found?.name ?? ''
}
