import { useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { postFunderTransfer } from '@controllers/FunderTransferController'

export default function PostTransaction({ id }) {
  const [open, setOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  }

  const handlePost = async () => {
    setLoading(true)
    const response = await postFunderTransfer(id)
    if (response.ok && response.status === 200) {
      console.dir(response)
      setLoading(false);
      navigate(0)
      setOpen(false)
    } else {
      setErrorText('Unable to post the funder transfer')
      setLoading(false);
    }
  }

  return <>
    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      Post Funder Transfer
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Post Funder Transfer</DialogTitle>
      <DialogContent>
        {errorText !== '' && <Alert severity="error">{errorText}</Alert>}
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to post this funder transfer?
        </DialogContentText>
        {
            loading && <LinearProgress />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button disabled={loading} onClick={handlePost} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </>
}
