import React from 'react'
// material
import { Box, Grid, Container, Typography } from '@material-ui/core'
import { useGridApiRef } from '@mui/x-data-grid'
// components
import Page from '../components/Page'

import {
  transactionsPath,
  pendingTransactionsPath,
  constructSearchQueryString,
  getToken,
  getHeaders,
  handleUnauthorized,
  transactionCSVPath
} from '../utils/constant'
import { TransactionsList } from '../components/_dashboard/transactions'

export default function Transactions() {
  const [loading, setLoading] = React.useState(true)
  const [downloading, setDownloading] = React.useState(false)
  const [transactions, setTransactions] = React.useState([])
  const [filter, setFilter] = React.useState('All')
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0
  })
  const [queryOptions, setQueryOptions] = React.useState({})
  const [error, setError] = React.useState('')
  
  const apiRef = useGridApiRef()

  function usePrevious(value) {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const previousFilter = usePrevious(filter)

  const filterTransactions = (e) => {
    setFilter(e.currentTarget.textContent)
  }

  const onFilterChange = (filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } })
    fetchTransactions(filterModel)
  }

  // TODO: Move fetch logic to controllers, replace useEffect with useQuery
  const fetchTransactions = (queryParams) => {
    const API_PATH =
      filter === 'All' ? transactionsPath(paginationModel.page + 1) : pendingTransactionsPath(paginationModel.page + 1)

    const QUERY_PARAMS = queryParams ? constructSearchQueryString(queryParams) : ''

    setLoading(true)
    
    fetch(`${API_PATH}${QUERY_PARAMS}`, {
      headers: getHeaders(),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status)
        }
        return response.json()
      })
      .then((data) => {
        setLoading(false)
        setTransactions(data.results)
        setError('')
        apiRef.current.setRowCount(data.count)
      })
      .catch((error) => {
        setLoading(false)
        if (error.message === '401') {
          handleUnauthorized()
          console.error('ERROR: ', error)
        }
        setError(error.message)
      })
  }

  const downloadFilteredTransactions = (queryParams) => {
    const API_PATH = transactionCSVPath(queryParams)
    setDownloading(true)

    fetch(API_PATH, {
        headers: getHeaders('csv'),
        credentials: 'include'
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.status)
      }
      return response.text()
     
    })
    .then(data => {
      const blob = new Blob([data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'transactions.csv') 
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
      setError('')
      setDownloading(false)
    })
    .catch((error) => {
      setDownloading(false)
      if (error.message === '401') {
        handleUnauthorized()
        console.error('ERROR: ', error)
      }
      setError(error.message)
    })
  }

  React.useEffect(() => {
    fetchTransactions(queryOptions?.filterModel)
  }, [paginationModel])

  React.useEffect(() => {
    if (previousFilter !== filter && paginationModel.page === 0) {
      fetchTransactions()
    } else {
      setPaginationModel({
        pageSize: 50,
        page: 0
      })
    }
  }, [filter])

  return (
    <Page title="Transactions | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Transactions</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TransactionsList
              error={error}
              isDownloading={downloading}
              isLoading={loading}
              transactions={transactions}
              onFilterClicked={filterTransactions}
              currentFilter={filter}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              apiRef={apiRef}
              queryOptions={queryOptions}
              onFilterChange={onFilterChange}
              handleCSVDownload={downloadFilteredTransactions}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
