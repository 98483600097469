import { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography } from '@material-ui/core'

import Page from '@components/Page.js'
import FundersList from '@components/_dashboard/funders/FundersList'
import * as funderController from '@controllers/FunderController'

const FundersPage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [funders, setFunders] = useState(true)

  const getFunders = async () => {
    const res = await funderController.getAllFunders()

    if (!res.ok) {
      setError(res.status)
    }
    else {
      const { results } = await res.json()
      setFunders(results)
    }

    setLoading(false)
  }

  useEffect(() => {
    getFunders()
  }, [])

  return <Page title="Funders | LMS">
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Funders</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading &&
            <Typography>Loading ....</Typography>
          }
          {error &&
            <Typography variant="error-text">Error: {error}</Typography>
          }

          {!loading && !error && <FundersList
            funders={funders}
          />}

        </Grid>
      </Grid>
    </Container>
  </Page>
}

export default FundersPage
