import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {filter} from 'lodash';

// material-ui
import {
  Box,
  Alert,
  Stack,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from 'react-datepicker';
import { createTransaction } from '../../../controllers/Transaction';

import 'react-datepicker/dist/react-datepicker.css';

export default function TransactionCreateForm() {
  const [showError, setShowError] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [showDebitField, setShowDebitField] = useState(false);
  const [showAmount, setShowAmount] = useState(true);
  const [showLiquidationReason, setShowLiquidationReason] = useState(false);
  const [showPayoutReason, setShowPayoutReason] = useState(false);
  const [showInterestRate, setShowInterestRate] = useState(false);
  const [showParentFileNumber, setShowParentFileNumber] = useState(false);
  const [showPrepaymentDiscountPercentage, setShowPrepaymentDiscountPercentage] = useState(false);
  const [showFunders, setShowFunders] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showFundingSources, setShowFundingSources] = useState(false);
  const [config] = useState(JSON.parse(localStorage.getItem('config')));

  const PostTransactionSchema = Yup.object().shape({
    accountFileNumber: Yup.string().required('Account File Number is required'),
    parentFileNumber: Yup.string(),
    category: Yup.string().required('Transaction Catagory is required'),
    type: Yup.string().required('Transaction Type is required'),
    interestRate: Yup.number().positive().min(0).max(1),
    funder: Yup.number().positive(),
    effectiveDate: Yup.string().required('Transaction effective date is required')
  });

  const getTransactionCategories = () => {
    const categories = filter(config.transaction_categories, c => {
      return c.name !== 'Interest' && c.name !== 'Penalty'
    });
    const values = [];
    categories.forEach((category) => {
      values.push({ id: category.id, label: category.name });
    });
    return values;
  };

  const getTransactionTypes = function (categoryId) {
    return new Promise((resolve) => {
      // console.log(categoryId);
      const categories = config.transaction_categories;
      categories.forEach((category) => {
        if (category.id === categoryId) {
          resolve(category.transaction_types);
        }
      });
    });
  };

  const getLiquidationReasons = function () {
    return config.liquidation_reasons;
  };

  const getPayoutReaons = function () {
    return config.payout_reasons;
  };

  const transactionTypeChange = function (transactionTypeId) {
    // initial draw
    if ([2].indexOf(transactionTypeId) > -1) {
      setShowProducts(true);
      setShowFunders(true);
      setShowFundingSources(true);
    }

    if ([2, 3].indexOf(transactionTypeId) > -1) {
      setShowInterestRate(true);
      setShowAmount(true);

      setShowPrepaymentDiscountPercentage(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
    }
    if ([4].indexOf(transactionTypeId) > -1) {
      setShowInterestRate(true);
      setShowParentFileNumber(true);
      setShowAmount(true);

      setShowPrepaymentDiscountPercentage(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([5, 6, 7, 8, 9, 10].indexOf(transactionTypeId) > -1) {
      setShowInterestRate(true);
      setShowAmount(true);

      setShowPrepaymentDiscountPercentage(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([11].indexOf(transactionTypeId) > -1) {
      setShowPrepaymentDiscountPercentage(true);
      setShowLiquidationReason(true);
      setShowPayoutReason(true);

      setShowAmount(false);
      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowFundingSources(false);
    }
    if ([12].indexOf(transactionTypeId) > -1) {
      setShowPrepaymentDiscountPercentage(true);
      setShowAmount(true);

      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([13].indexOf(transactionTypeId) > -1) {
      setShowAmount(true);

      setShowPrepaymentDiscountPercentage(false);
      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([14, 15, 16].indexOf(transactionTypeId) > -1) {
      setShowDebitField(true);
      setShowAmount(true);

      setShowPrepaymentDiscountPercentage(false);
      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([17].indexOf(transactionTypeId) > -1) {
      setShowLiquidationReason(true);

      setShowDebitField(false);
      setShowAmount(false);
      setShowPrepaymentDiscountPercentage(false);
      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
    if ([20].indexOf(transactionTypeId) > -1) {
      setShowAmount(true);

      setShowDebitField(false);
      setShowPrepaymentDiscountPercentage(false);
      setShowInterestRate(false);
      setShowParentFileNumber(false);
      setShowDebitField(false);
      setShowLiquidationReason(false);
      setShowPayoutReason(false);
      setShowFundingSources(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      accountFileNumber: '',
      parentFileNumber: '',
      debit: false,
      categories: getTransactionCategories(),
      category: '',
      types: [],
      type: '',
      amount: '',
      interestRate: '',
      prepaymentDiscountPercentage: 0,
      effectiveDate: '',
      liquidationReasons: getLiquidationReasons(),
      liquidationReason: '',
      payoutReasons: getPayoutReaons(),
      payoutReason: '',
      funders: config.funders,
      funder: '',
      products: config.products,
      product: '',
      fundingSources: config.fundingSources,
      fundingSource: ''
    },
    validationSchema: PostTransactionSchema,
    onSubmit: () => {
      createTransaction(formik.values).then((response) => {
        if (response.success) {
          setShowError(false);
          formik.setSubmitting(false);
          window.location = `/dashboard/transactions/${response.data.id}`;
        } else {
          console.log(response);
          window.scrollTo(0, 0);
          setApiErrors(response.errors);
          setShowError(true);
          formik.setSubmitting(false);
        }
      });
    }
  });

  const { errors, touched, values, isSubmitting, setFieldValue, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      {showError && (
        <Box>
          {apiErrors.length > 0 &&
            apiErrors.forEach((error) => {
              <Alert severity="error">Test</Alert>;
              <Alert severity="error">{error}</Alert>;
            })}

          <Alert severity="error">Unable to create transaction. Please try again!</Alert>
        </Box>
      )}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ my: 2 }}>
          <FormControl>
            <InputLabel htmlFor="transaction-category">Transaction Category</InputLabel>
            <Select
              label="Transaction Category"
              labelId="transaction-category-label"
              id="transaction-category"
              value={values.category}
              onChange={async (e) => {
                const { value } = e.target;
                const _types = await getTransactionTypes(value);
                setFieldValue('category', value);
                setFieldValue('type', '');
                setFieldValue('types', _types);
              }}
              error={Boolean(touched.category && errors.category)}
            >
              {values.categories &&
                values.categories.map((value, index) => (
                  <MenuItem value={value.id} name={value.label} key={index}>
                    {value.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText error>{touched.category && errors.category}</FormHelperText>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="transaction-type">Transaction Type</InputLabel>
            <Select
              label="Transaction Type"
              labelId="transaction-type-label"
              id="transaction-type"
              value={values.type}
              onChange={(e) => {
                setFieldValue('type', e.target.value);
                setFieldValue('parentFileNumber', '');
                transactionTypeChange(e.target.value);
              }}
              error={Boolean(touched.type && errors.type)}
            >
              {values.types &&
                values.types.map((value, index) => (
                  <MenuItem key={index} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText error>{touched.type && errors.type}</FormHelperText>
          </FormControl>

          {showFunders && (
            <FormControl>
              <InputLabel htmlFor="funder">Funder</InputLabel>
              <Select
                label="Funder"
                labelId="funder-label"
                id="funder"
                value={values.funder}
                onChange={(e) => {
                  setFieldValue('funder', e.target.value);
                }}
                error={Boolean(touched.funders && errors.funders)}
              >
                {values.funders &&
                  values.funders.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {showProducts && (
            <FormControl>
              <InputLabel htmlFor="product">Product</InputLabel>
              <Select
                label="Product"
                labelId="product-label"
                id="product"
                value={values.product}
                onChange={(e) => {
                  setFieldValue('product', e.target.value);
                }}
                error={Boolean(touched.product && errors.product)}
              >
                {values.products &&
                  values.products.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

        {showFundingSources && (
            <FormControl>
              <InputLabel htmlFor="funding-source">Funding Source</InputLabel>
              <Select
                label="Funding Source"
                labelId="funding-source-label"
                id="funding-source"
                value={values.fundingSource}
                onChange={(e) => {
                  setFieldValue('fundingSource', e.target.value);
                }}
                error={Boolean(touched.fundingSource && errors.fundingSource)}
              >
                {values.fundingSources &&
                  values.fundingSources.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {`${value.institution}-${value.type_of_capital}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            autoComplete="fileNumber"
            type="text"
            label="Account File Number"
            {...getFieldProps('accountFileNumber')}
            error={Boolean(touched.accountFileNumber && errors.accountFileNumber)}
            helperText={touched.accountFileNumber && errors.accountFileNumber}
          />
          {showParentFileNumber && (
            <FormControl>
              <TextField
                fullWidth
                autoComplete="fileNumber"
                type="text"
                label="Parent Account File Number"
                {...getFieldProps('parentFileNumber')}
                error={Boolean(touched.parentFileNumber && errors.parentFileNumber)}
                helperText={touched.parentFileNumber && errors.parentFileNumber}
              />
              {/* <FormHelperText id="interest-helper-text">
                Enter value between 0 and 1.
              </FormHelperText> */}
            </FormControl>
          )}

          {showDebitField && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.debit}
                  name="debit"
                  color="primary"
                  onChange={() => {
                    setFieldValue('debit', !values.debit);
                  }}
                />
              }
              label="Debit"
            />
          )}

          {showAmount && (
            <TextField
              fullWidth
              autoComplete="amount"
              type="text"
              label="Amount"
              {...getFieldProps('amount')}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          )}

          {showInterestRate && (
            <TextField
              fullWidth
              autoComplete="interestRate"
              type="number"
              InputProps={{
                inputProps: {
                  max: 1,
                  min: 0
                }
              }}
              label="Interest Rate (enter value between 0 and 1)"
              {...getFieldProps('interestRate')}
              error={Boolean(touched.interestRate && errors.interestRate)}
              helperText={touched.interestRate && errors.interestRate}
            />
          )}

          {showPrepaymentDiscountPercentage && (
            <TextField
              fullWidth
              autoComplete="prepaymentDiscountPercentage"
              type="number"
              label="Prepayment Discount Percentage (enter value between 0 and 1)"
              {...getFieldProps('prepaymentDiscountPercentage')}
              error={Boolean(
                touched.prepaymentDiscountPercentage && errors.prepaymentDiscountPercentage
              )}
              helperText={
                touched.prepaymentDiscountPercentage && errors.prepaymentDiscountPercentage
              }
            />
          )}

          <DatePicker
            {...getFieldProps('effectiveDate')}
            dateFormat="yyyy-MM-dd"
            selected={(values.effectiveDate && new Date(values.effectiveDate)) || null}
            onChange={(val) => {
              setFieldValue(
                'effectiveDate',
                `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
              );
            }}
            customInput={
              <TextField
                label="Effective Date"
                error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                helperText={touched.effectiveDate && errors.effectiveDate}
              />
            }
          />
          {showLiquidationReason && (
            <FormControl>
              <InputLabel htmlFor="liquidation-reason">Liquidation Reason</InputLabel>
              <Select
                label="Liquidation Reason"
                labelId="liquidation-reason-label"
                id="liquidation-reason"
                value={values.liquidationReason}
                onChange={(e) => {
                  setFieldValue('liquidationReason', e.target.value);
                }}
                error={Boolean(touched.type && errors.type)}
              >
                {values.liquidationReasons &&
                  values.liquidationReasons.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {showPayoutReason && (
            <FormControl>
              <InputLabel htmlFor="payout-reason">Payout Reason</InputLabel>
              <Select
                label="Payout Reason"
                labelId="payout-reason-label"
                id="payout-reason"
                value={values.payoutReason}
                onChange={(e) => {
                  setFieldValue('payoutReason', e.target.value);
                }}
                error={Boolean(touched.type && errors.type)}
              >
                {values.payoutReasons &&
                  values.payoutReasons.map((value, index) => (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>{touched.type && errors.type}</FormHelperText>
            </FormControl>
          )}
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Create Transaction
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
