import { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Page from '@components/Page.js'
import FundingSourceDetails from '@components/_dashboard/funding-source/FundingSourceDetails'
import AccountsList from '@components/_dashboard/accounts/AccountsList'
import * as fundingSourceController from '@controllers/FundingSourceController'

const FundingSource = () => {
  const { id } = useParams()
  // control states
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [fundingSource, setFundingSource] = useState()

  const getFundingSource = async () => {
    const res = await fundingSourceController.getFundingSource(id)

    if (!res.ok) {
      setError(res.status)
    } else {
      const fundingSource = await res.json()
      setFundingSource(fundingSource)
    }
    setLoading(false)
  }

  // initial load
  useEffect(() => {
    getFundingSource()
  }, [])

  return (
    <Page title={`${fundingSource?.key ?? 'Funding Source'} | LMS`}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{`Funding Source: ${
            fundingSource?.key ?? '...Loading'
          }`}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loading && <Typography>Loading ....</Typography>}
            {error && (
              <Typography variant="error-text">Error: {error}</Typography>
            )}

            {!loading && fundingSource && (
              <>
                <FundingSourceDetails fundingSource={fundingSource} />
                <Box sx={{ pt: 5 }}>
                  <Typography variant="h4">Owned Accounts:</Typography>
                </Box>
                <AccountsList
                  filters={{ fundingSourceKey: fundingSource.key }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default FundingSource
