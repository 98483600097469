// material
import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Label from '../../Label';

import { 
  DataGrid, 
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar'
import LinearProgress from '@mui/material/LinearProgress';
import { Link as RouterLink } from 'react-router-dom';

import {
  fetchTransactionType,
  fetchTransactionFunderName,
  fetchTransactionProductName
} from '@utils/fetchTransactionsMetaData';
import { fCurrency, fPercent } from '@utils/formatNumber';
import { fDateTimeSuffix } from '@utils/formatTime';

import TransactionFilter from './TransactionFilter';

// ----------------------------------------------------------------------

function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  )
}

export default function TransactionsList({
  transactions,
  paginationModel,
  setPaginationModel,
  currentFilter,
  onFilterClicked,
  apiRef,
  isLoading,
  isDownloading,
  onFilterChange,
  handleCSVDownload,
  disableFilter,
  error
}) {

  const columns = [
    { field: 'id', headerName: 'Id', headerAlign: 'center', align: 'center', renderCell: (params) => {
      if (params?.row?.id) {
        return (
          <Link
            to={`/dashboard/transactions/${params?.row?.id}`}
            underline="hover"
            component={RouterLink}
          >
            {params?.row?.id}
          </Link>
        )
      }
    }},
    { field: 'file_number', headerName: 'File Number', headerAlign: 'center', align: 'center' },
    { field: 'funder', headerName: 'Funder', headerAlign: 'center', align: 'center', valueGetter: (value, row) => fetchTransactionFunderName(row.funder) },
    { field: 'product', headerName: 'Product', headerAlign: 'center', align: 'center', valueGetter: (value, row) => fetchTransactionProductName(row.product) },
    { field: 'type', headerName: 'Transaction Type', headerAlign: 'center', align: 'left', valueGetter: (value, row) => fetchTransactionType(row.type)},
    { field: 'status', headerName: 'Transaction Status', headerAlign: 'center', align: 'center', renderCell: (params) => {
      if (params?.row?.posting_date !== null ) {
        return <Label color="primary">Posted </Label>
      }
      return (
        <Label color="secondary">Pending </Label>
      )
    } },
    { field: 'reverse_transaction_reference_number', headerAlign: 'center', align: 'center', headerName: 'Reversing Transaction' },
    { field: 'total_amount', headerName: 'Total Amount', headerAlign: 'center', align: 'right', valueFormatter: (value) => fCurrency(value) },
    { field: 'posting_date', headerName: 'Posted Date', headerAlign: 'center' },
    { field: 'effective_date', headerName: 'Effective Date', headerAlign: 'center' },
    { field: 'created_by', headerName: 'Created By', headerAlign: 'center' },
    { field: 'posted_at', headerName: 'Posted At', headerAlign: 'center', valueFormatter: (value) => fDateTimeSuffix(value) },
    { field: 'principal_amount', headerName: 'Principal Amount', headerAlign: 'center', align: 'right', valueFormatter: (value) => fCurrency(value) },
    { field: 'interest_amount', headerName: 'Interest Amount', headerAlign: 'center', align: 'right', valueFormatter: (value) => fCurrency(value) },
    { field: 'interest_rate', headerName: 'Interest Rate', headerAlign: 'center', align: 'right', valueFormatter: (value) => fPercent(value) },
    { field: 'debit', headerName: 'Debit', headerAlign: 'center' }
  ]

  return (
    <Box>
      {error && <Snackbar message={error} />}
      {!disableFilter && 
        <TransactionFilter 
          handleSearch={onFilterChange} 
          handleCSVDownload={handleCSVDownload} 
          isLoading={isLoading}
          isDownloading={isDownloading}
        />}
      <Label color={`${currentFilter === 'All' ? 'success' : 'default'}`} onClick={onFilterClicked}>
        All
      </Label>
      <Label
        color={`${currentFilter === 'Pending' ? 'success' : 'default'}`}
        onClick={onFilterClicked}
      >
        Pending
      </Label>
      <TableContainer style={{ height: '1000px', overflow: "auto" }} component={Paper}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 2
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important"
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "200px !important"
            },
            color: isLoading ? '#999' : 'black' // Dim text during loading
          }}
          loading={isLoading}
          slots={{ 
            toolbar: CustomGridToolbar,
            loadingOverlay: LinearProgress
          }}
          rows={transactions}
          columns={columns}
          initialState={{
            pagination: {
              rowCount: 0,
              paginationModel: { page: 0, pageSize: 50 }
            }
          }}
          autoHeight={false}
          apiRef={apiRef}
          pageSizeOptions={[50]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}   
        />
      </TableContainer>
    </Box>
  );
}
