import {
  funderListPath,
  funderPath,
  getHeaders
} from '@utils/constant'

const standardOptions = {
  headers: getHeaders(),
  credentials: 'include'
}

export const getAllFunders = async () =>
  await fetch(funderListPath(), standardOptions)

export const getFunder = async (id) =>
  await fetch(funderPath(id), standardOptions)
