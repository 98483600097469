import { Grid } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import { fCurrency } from '../../../utils/formatNumber';
import { fDateTime } from '../../../utils/formatTime';
import {
  fetchTransactionType,
  fetchTransactionPayoutReason,
  fetchTransactionLiquidationReason,
  fetchTransactionFunderName,
  fetchTransactionProductName
} from '../../../utils/fetchTransactionsMetaData';
import ReverseTransaction from './ReverseTransaction';
import PostTransaction from './PostTransaction';
import DeleteTransaction from './DeleteTransaction';
import React from 'react';

const RootStyledDiv = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  WebkitBoxPack: 'justify',
  borderTop: '1px gray solid',
  borderBottom: '1px gray solid'
});

const LabelDiv = styled('div')({
  color: 'rgb(62, 62, 60)',
  fontSize: '14px'
});

const DataDiv = styled('div')({
  color: 'rgb(8, 7, 7)',
  fontSize: '16px'
});

export default function TransactionInformation({ transaction }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Transaction Id</LabelDiv>
          <DataDiv>{transaction.id}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>File Number</LabelDiv>
          <DataDiv>{transaction.file_number}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Debit</LabelDiv>
          <DataDiv>{transaction.debit ? 'True' : 'False'}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Type</LabelDiv>
          <DataDiv>{fetchTransactionType(transaction.type)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Funder</LabelDiv>
          <DataDiv>{fetchTransactionFunderName(transaction.funder)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Product</LabelDiv>
          <DataDiv>{fetchTransactionProductName(transaction.product)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Total Amount</LabelDiv>
          <DataDiv>{fCurrency(transaction.total_amount)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Principal Amount</LabelDiv>
          <DataDiv>{fCurrency(transaction.principal_amount)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Effective Date</LabelDiv>
          <DataDiv>{fDateTime(transaction.effective_date)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Posting Date</LabelDiv>
          <DataDiv>
            {transaction.posting_date !== null ? fDateTime(transaction.posting_date) : ''}
          </DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Interest Amount</LabelDiv>
          <DataDiv>{fCurrency(transaction.interest_amount)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Charge Fee Amount</LabelDiv>
          <DataDiv>{fCurrency(transaction.charge_fee_amount)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Penalty Amount</LabelDiv>
          <DataDiv>{fCurrency(transaction.penalty_amount)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Interest Rate</LabelDiv>
          <DataDiv>{(transaction.interest_rate * 100).toFixed(2)}%</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Payout Reason</LabelDiv>
          <DataDiv>
            {transaction.payout_reason !== null
              ? fetchTransactionPayoutReason(transaction.payout_reason)
              : ''}
          </DataDiv>
        </RootStyledDiv>
      </Grid>

      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Liquidation Reason</LabelDiv>
          <DataDiv>
            {transaction.liquidation_reason !== null
              ? fetchTransactionLiquidationReason(transaction.liquidation_reason)
              : ''}
          </DataDiv>
        </RootStyledDiv>
      </Grid>

      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Reversed Transaction</LabelDiv>
          <DataDiv>
            {transaction.reverse_transaction !== null ? (
              <a href={`/dashboard/transactions/${transaction.reverse_transaction}`}>
                {transaction.reverse_transaction_reference_number}
              </a>
            ) : (
              ''
            )}
          </DataDiv>
        </RootStyledDiv>
      </Grid>

      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Related Reason</LabelDiv>
          <DataDiv>{transaction.related_transaction}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Created By</LabelDiv>
          <DataDiv>{transaction.created_by}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6}>
        <RootStyledDiv>
          <LabelDiv>Reference Number</LabelDiv>
          <DataDiv>{transaction.reference_number}</DataDiv>
        </RootStyledDiv>
      </Grid>
      {transaction.posting_date != null && transaction.is_reversible && (
        <Grid item xs={6}>
          <ReverseTransaction transactionId={transaction.id} />
        </Grid>
      )}
      {transaction.posting_date == null && (
        <React.Fragment>
          <Grid item xs={6}>
            <PostTransaction transactionId={transaction.id} />
          </Grid>
          <Grid item xs={6}>
            <DeleteTransaction transactionId={transaction.id} />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}
