// material
import { Box, Container, Typography, Stack, Button } from '@material-ui/core';
// components
import Page from '../components/Page';
import { getTransactionReport, getOGLReport, getPortfolioReport } from '../controllers/Reports';

const handleTransactionReportClick = () => {
  getTransactionReport();
};

const handleOGLReportClick = () => {
  getOGLReport();
};

const handlePortfolioReportClick = () => {
  getPortfolioReport();
};

export default function Reports() {
  return (
    <Page title="Reports | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography varient="h4">Reports</Typography>
        </Box>
        {/* <Grid container spacing={3}> */}
        <Stack direction="row" spacing={4}>
          <Button variant="outlined" color="primary" onClick={handleTransactionReportClick}>
            Transaction Report
          </Button>
          <Button variant="outlined" color="primary" onClick={handleOGLReportClick}>
            OGL Report
          </Button>
          <Button variant="outlined" color="primary" onClick={handlePortfolioReportClick}>
            Portfolio Report
          </Button>
        </Stack>
        {/* </Grid> */}
      </Container>
    </Page>
  );
}
