import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { CircularProgress, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';

import AccountRow from './AccountRow';
import { accountsPath } from '@utils/constant'
import { PagedDataProvider, usePagedData } from '@controllers/PagedDataProvider'

// ----------------------------------------------------------------------

const Pagination = () => {
  const {
    count, pageNumber, rowsPerPage,
    handlePageChange, handleRowsPerPageChange
  } = usePagedData()

  return <TablePagination
    component="div"
    count={count}
    rowsPerPageOptions={[5, 10, 25, 50]}
    rowsPerPage={rowsPerPage}
    page={pageNumber -1}
    onPageChange={handlePageChange}
    onRowsPerPageChange={handleRowsPerPageChange}
  />
}

const AccountsList = () => {
  const { loading, error, data } = usePagedData()

  return <Box>
    <Pagination />
    <TableContainer component={Paper}>
      <Table aria-label="all accounts table">
        <TableHead>
          <TableRow>
            <TableCell>File Number</TableCell>
            <TableCell align="center">Funding Source</TableCell>
            <TableCell align="center">Funder</TableCell>
            <TableCell align="center">Product</TableCell>
            <TableCell align="center">Active Interest Rate</TableCell>
            <TableCell align="center">Principal Balance</TableCell>
            <TableCell align="center">Accured Interest</TableCell>
            <TableCell align="center">Charge/Fee Balance</TableCell>
            <TableCell align="center">Total Outstanding Balance</TableCell>
            <TableCell align="center">Parent File Number</TableCell>
            <TableCell align="center">Created Date</TableCell>
            <TableCell align="center">Last Modified Date</TableCell>
          </TableRow>
        </TableHead>
        {loading && <CircularProgress />}
        {error &&
          <Typography variant="error-text">Error: {error}</Typography>
        }

        {!loading && data && <TableBody>
          {data.map((account) => (
            <AccountRow account={account} key={account.file_number} />
          ))}
        </TableBody> }
      </Table>
    </TableContainer>
    <Pagination />
  </Box>
}

const AccountListWithData = ({filters}) =>
  <PagedDataProvider filters={filters} endpoint={accountsPath}>
    <AccountsList />
  </PagedDataProvider>

export default AccountListWithData
