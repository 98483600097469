const API_VERSION = '/api/v1';

function apiEndpoint() {
  return process.env.REACT_APP_LMS_API;
}

export function servicesPortalEndpoint() {
  return process.env.REACT_APP_LMS_SERVICES;
}

export function getToken() {
  return localStorage.getItem('token');
}

export function getConfig() {
  return `${apiEndpoint()}${API_VERSION}/config/categories/`;
}

export function fetchCSRFToken() {
  return `${apiEndpoint()}/csrf/`;
}

export function oauthLoginPath() {
  return `${apiEndpoint()}${API_VERSION}/token/`;
}

export function loginPath() {
  return `${apiEndpoint()}/oauth2/login`;
}

export function logoutPath() {
  return `${apiEndpoint()}/oauth2/logout`;
}

export function userInfoPath() {
  return `${apiEndpoint()}${API_VERSION}/users/me/`;
}

export function portfolioBalances() {
  return `${apiEndpoint()}${API_VERSION}/reporting/portfolio_balance/`;
}

export function accountsPath({ pageNumber = 1, pageSize, funderKey, fundingSourceKey }) {
  let url = `${apiEndpoint()}${API_VERSION}/account/?page=${pageNumber}`;
  if (pageSize) url += `&page_size=${pageSize}`
  if (funderKey) url += `&funder_key=${funderKey}`
  if (fundingSourceKey) url += `&funding_source_key=${fundingSourceKey}`
  return url
}

export function singleAccountPath(accountFileNumber) {
  return `${apiEndpoint()}${API_VERSION}/account/${accountFileNumber}/`;
}

export function updateAccountInterestRatePath() {
  return `${apiEndpoint()}${API_VERSION}/interest-rate/`;
}

export function transactionsPath(pageNumber) {
  return pageNumber !== undefined
    ? `${apiEndpoint()}${API_VERSION}/transaction/?page=${pageNumber}`
    : `${apiEndpoint()}${API_VERSION}/transaction/`;
}

export function pendingTransactionsPath(pageNumber) {
  return `${apiEndpoint()}${API_VERSION}/transaction/?posted=false&page=${pageNumber}`;
}

export function relatedTransactionsPath(accountFileNumber, pageNumber = 1) {
  return `${apiEndpoint()}${API_VERSION}/transaction/?file_number=${accountFileNumber}&page=${pageNumber}`;
}

export function relatedPendingTransactionsPath(accountFileNumber, pageNumber = 1) {
  return `${apiEndpoint()}${API_VERSION}/transaction/?file_number=${accountFileNumber}&posted=false&page=${pageNumber}`;
}

export function singleTransactionPath(transactionId) {
  return `${apiEndpoint()}${API_VERSION}/transaction/${transactionId}/`;
}

export function postTransactionPath(transactionId) {
  return `${apiEndpoint()}${API_VERSION}/transaction/${transactionId}/post_transaction/`;
}

export function reverseTransactionPath(transactionId) {
  return `${apiEndpoint()}${API_VERSION}/transaction/${transactionId}/reverse_transaction/`;
}

export function transactionReportPath() {
  return `${apiEndpoint()}${API_VERSION}/reporting/transaction_report/`;
}

export function oglReportPath() {
  return `${apiEndpoint()}${API_VERSION}/reporting/ogl_report/`;
}

export function portfolioReportPath() {
  return `${apiEndpoint()}${API_VERSION}/reporting/portfolio_report/`;
}

export function funderListPath() {
  return `${apiEndpoint()}${API_VERSION}/funder/`;
}

export function funderPath(funderId) {
  return `${apiEndpoint()}${API_VERSION}/funder/${funderId}/`
}

export function fundingSourceListPath() {
  return `${apiEndpoint()}${API_VERSION}/funding-source/`;
}

export function fundingSourcePath(fundingSourceId) {
  return `${apiEndpoint()}${API_VERSION}/funding-source/${fundingSourceId}/`
}

export function productListPath() {
  return `${apiEndpoint()}${API_VERSION}/product/`
}

export function funderTransferListPath() {
  return `${apiEndpoint()}${API_VERSION}/funder-transfer/`
}

export function funderTransferCreatePath() {
  return `${apiEndpoint()}${API_VERSION}/funder-transfer/`
}

export function funderTransferPath(id) {
  return `${apiEndpoint()}${API_VERSION}/funder-transfer/${id}/`
}

export function funderTransferUpdatePath(id) {
  return `${apiEndpoint()}${API_VERSION}/funder-transfer/${id}/`
}

export function funderTransferPostPath(id) {
  return `${apiEndpoint()}${API_VERSION}/funder-transfer/${id}/post_funder_transfer/`
}

export function constructSearchQueryString(queries) {
  let searchString = ''
  for (const index in queries) {
    searchString += `&${queries[index].key.trim()}=${queries[index].value.trim()}`
  }
  return searchString
}

export function transactionCSVPath(queries) {
  let queryParams = constructSearchQueryString(queries)
  return `${apiEndpoint()}${API_VERSION}/transaction/csv${queryParams ? '?' : ''}${queryParams.substring(1)}`
}

export function handleUnauthorized() {
  localStorage.removeItem('token');
  window.location = '/login';
}

export const getHeaders = (fileType) => {
  if (process.env.REACT_APP_ENV === 'local') {
    if (fileType === 'csv') {
      return {
        'Content-Type': 'text/csv;charset=UTF-8',
        Authorization: `Bearer ${getToken()}`
      }
    }
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    };
  }
  return {
    'Content-Type': 'application/json'
  };
};
