import React from 'react';
import { useParams } from 'react-router-dom';

// material-ui
import { Stack, Container, Typography } from '@material-ui/core';
import TransactionCreateForm from '../components/_dashboard/transactions/TransactionCreateForm';

// components
import Page from '../components/Page';

export default function CreateTransaction() {
  return (
    <Page title="Post Transaction | LMS">
      <Container maxWidth="xl">
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Create a Transaction
          </Typography>
        </Stack>
        <TransactionCreateForm />
      </Container>
    </Page>
  );
}
