import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid, Container, Typography } from '@material-ui/core'

import * as accountController from '@controllers/Account'
import Page from '@components/Page.js'
import { AccountInformation } from '@components/_dashboard/account'

const SellAccount = () => {
  const {id} = useParams()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [account, setAccount] = useState()

  const getAccount = async () => {
    const { data, success } = await accountController.getAccount(id)
    if (success) {
      setAccount(data)
    }
    else {
      setError('An error occurred')
    }
    setLoading(false)
    console.dir(data)
  }

  useEffect(() => {
    getAccount()
  }, [id])

  return <Page title={`Sell account ${account?.id ?? '...'} | LMS`}>
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Sell account</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading &&
            <Typography>Loading ....</Typography>
          }
          {error &&
            <Typography variant="error-text">Error: {error}</Typography>
          }
          {account && <AccountInformation account={account} mode="sell"/>}
        </Grid>
      </Grid>
    </Container>
  </Page>
}


export default SellAccount
