import {
  productListPath,
  getHeaders
} from '@utils/constant'

const standardOptions = {
  headers: getHeaders(),
  credentials: 'include'
}

export const getAllProducts = async () =>
  await fetch(productListPath(), standardOptions)

