import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
// import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import bookFill from '@iconify/icons-eva/book-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import starFill from '@iconify/icons-eva/star-fill';
import bankFill from '@iconify/icons-ant-design/bank-filled';
import homeSaleIcon from '@iconify/icons-bx/transfer';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Funders',
    path: '/dashboard/funders',
    icon: getIcon(bankFill)
  },
  {
    title: 'Funder Transfers',
    path: '/dashboard/funder-transfers',
    icon: getIcon(homeSaleIcon)
  },
  {
    title: 'Funding Sources',
    path: '/dashboard/funding-sources',
    icon: getIcon(starFill)
  },
  {
    title: 'Accounts',
    path: '/dashboard/accounts',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Transactions',
    path: '/dashboard/transactions',
    icon: getIcon(bookFill)
  },
  {
    title: 'Create Transaction',
    path: '/dashboard/create',
    icon: getIcon(lockFill)
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon(shoppingBagFill)
  }
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
