export const funderNameFromKey = (key) => {
	const rawConfig = localStorage.getItem("config");
	const config = JSON.parse(rawConfig);
	return config.funders.find((f) => f.key === key).name;
};

export const productNameFromKey = (key) => {
	const rawConfig = localStorage.getItem("config");
	const config = JSON.parse(rawConfig);
	return config.products.find((f) => f.key === key).name;
};

export const fundingSourceNameFromKey= (key) => {
	const rawConfig = localStorage.getItem("config");
	const config = JSON.parse(rawConfig);
	const fundingSource = config.fundingSources.find((f) => f.key === key);
	return `${fundingSource.institution}-${fundingSource.type_of_capital}`;
};


export const fundingSourceNameFromId = (id) => {
	const rawConfig = localStorage.getItem("config");
	const config = JSON.parse(rawConfig);
	const fundingSource = config.fundingSources.find((f) => f.id === id);
	return `${fundingSource.institution}-${fundingSource.type_of_capital}`;
};
