import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'

const fPercent = (v) => `${(v * 100).toFixed(2)}%`


const Row = ({funder}) => <TableRow key={`funder-${funder.key}`}>
  <TableCell component="th" scope="row">
    <Link
      to={`/dashboard/funders/${funder.id}`}
      underline="hover"
      component={RouterLink}
    >
      {funder.key}
    </Link>
  </TableCell>
  <TableCell align="center">{funder.name}</TableCell>
  <TableCell align="center">{fPercent(funder.purchase_premium)}</TableCell>
  <TableCell align="center">{fPercent(funder.portfolio_premium)}</TableCell>
  <TableCell align="center">{fPercent(funder.rate_reset_premium)}</TableCell>
  <TableCell align="center">{fPercent(funder.clawback_1)}</TableCell>
  <TableCell align="center">{fPercent(funder.clawback_2)}</TableCell>
  <TableCell align="center">{fPercent(funder.clawback_3)}</TableCell>
  <TableCell align="center">{funder.status}</TableCell>
</TableRow>


export default function TransactionsList({
  funders
}) {

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="all funders table">
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Purchase Premium</TableCell>
              <TableCell align="center">Portfolio Premium</TableCell>
              <TableCell align="center">Rate Reset Premium</TableCell>
              <TableCell align="center">Clawback 1</TableCell>
              <TableCell align="center">Clawback 2</TableCell>
              <TableCell align="center">Clawback 3</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funders.map((funder) => (
              <Row funder={funder} key={funder.key} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
