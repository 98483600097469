import React from 'react';
import { useParams } from 'react-router-dom';

// material
import { Box, Grid, Container, Typography, CircularProgress } from '@material-ui/core';
// components
import Page from '../components/Page';
import TransactionInformation from '../components/_dashboard/transactions/TransactionInformation';

import { getTransaction } from '../controllers/Transaction';

export default function Transaction() {
  const [notFound, setNotFound] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [transaction, setTransaction] = React.useState({});
  const [params] = React.useState(useParams());

  React.useEffect(() => {
    getTransaction(params.id).then((response) => {
      if (response.success) {
        setTransaction(response.data);
        setLoading(false);
      } else {
        setNotFound(true);
      }
    });
  }, []);

  return (
    <Page title="Transaction | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Transaction</Typography>
        </Box>
        <Grid container spacing={3}>
          {notFound ? (
            <Grid item xs={12}>
              Invalid Transaction Id
            </Grid>
          ) : (
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                <TransactionInformation transaction={transaction} />
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
