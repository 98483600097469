import { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Page from '@components/Page.js'
import FunderDetails from '@components/_dashboard/funders/FunderDetails'
import AccountsList from '@components/_dashboard/accounts/AccountsList'
import * as funderController from '@controllers/FunderController'

const FundersPage = () => {
  const {id} = useParams()
  // control states
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [funder, setFunder] = useState()

  const getFunder = async () => {
    const res = await funderController.getFunder(id)

    if (!res.ok) {
      setError(res.status)
    }
    else {
      const funder = await res.json()
      setFunder(funder)
    }
    setLoading(false)
  }


  // initial load
  useEffect(() => {
    getFunder()
  }, [])

  return <Page title={`${funder?.name ?? 'Funder'} | LMS`}>
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">{`Funder: ${funder?.name ?? '...Loading'}`}</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading &&
            <Typography>Loading ....</Typography>
          }
          {error &&
            <Typography variant="error-text">Error: {error}</Typography>
          }


          {!loading && funder && <>
            <FunderDetails funder={funder} />
            <Box sx={{ pt: 5 }}>
              <Typography variant="h4">Owned Accounts:</Typography>
            </Box>
            <AccountsList
              filters={ {funderKey: funder.key }}
            />
          </>}

        </Grid>
      </Grid>
    </Container>
  </Page>
}

export default FundersPage
