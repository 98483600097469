import { useState } from 'react'
import { Grid, Button } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useNavigate, Link } from 'react-router-dom';

import { fCurrency } from '../../../utils/formatNumber';
import { fDate } from '../../../utils/formatTime';
import {
  fetchTransactionFunderName,
  fetchTransactionProductName
} from '@utils/fetchTransactionsMetaData'
import SellAccount from './SellAccount'
import { fundingSourceNameFromId } from '@utils/mapping';

const RootStyledDiv = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  WebkitBoxPack: 'justify',
  borderTop: '1px gray solid',
  borderBottom: '1px gray solid'
});

const LabelDiv = styled('div')({
  color: 'rgb(62, 62, 60)',
  fontSize: '14px'
});

const DataDiv = styled('div')({
  color: 'rgb(8, 7, 7)',
  fontSize: '16px'
});

export default function AccountInformation({ account }) {
  const navigate = useNavigate();
  const [ mode, setMode ] = useState('view')

  const handleClick = () => {
    console.log(account.file_number);
    navigate(`/dashboard/interest-rates/${account.file_number}`, { replace: true });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>File Number</LabelDiv>
          <DataDiv>{account.file_number}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Parent File Number</LabelDiv>
          <DataDiv>
            {account.parent_file_number !== null ? (
              <a href={`/dashboard/accounts/${account.parent_file_number}`}>
                {account.parent_file_number}
              </a>
            ) : (
              ''
            )}
          </DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Funder</LabelDiv>
          <DataDiv>{fetchTransactionFunderName(account.funder)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Product</LabelDiv>
          <DataDiv>{fetchTransactionProductName(account.product)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Funding Source</LabelDiv>
          <DataDiv>{fundingSourceNameFromId(account.funding_source)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Principal Balance</LabelDiv>
          <DataDiv>{fCurrency(account.total_principal)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Active Interest Rate</LabelDiv>
          <DataDiv>{(account.active_interest_rate * 100).toFixed(2)}%</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Total Interest</LabelDiv>
          <DataDiv>{fCurrency(account.total_interest)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Total Charge Fees</LabelDiv>
          <DataDiv>{fCurrency(account.total_charge_fee)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Total Outstanding Amount</LabelDiv>
          <DataDiv>{fCurrency(account.total_outstanding)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Created By</LabelDiv>
          <DataDiv>{account.created_by}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Created Date</LabelDiv>
          <DataDiv>{fDate(account.created_at)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <RootStyledDiv>
          <LabelDiv>Last Updated Date</LabelDiv>
          <DataDiv>{fDate(account.updated_at)}</DataDiv>
        </RootStyledDiv>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <></>
      </Grid>
      <Grid item xs={6} textAlign="left">
        {mode === 'view' && <>
          <Button variant="outlined" color="primary" onClick={handleClick}>
            Interest Rates
          </Button>

          <Button variant="outlined" sx={{ ml: 3}} color="primary"
            onClick={() => setMode('sell')}>
            Sell Account
          </Button>
        </>}
        {mode === 'sell' &&
          <SellAccount account={account} onCancel={() => setMode('view')} />
        }
      </Grid>
    </Grid>
  );
}
