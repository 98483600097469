import { useState, useEffect, createContext, useContext } from 'react'
import { handleUnauthorized, getHeaders } from '@utils/constant'

const Context = createContext()

export const PagedDataProvider = ({children, endpoint, filters}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [nextPage, setNextPage] = useState()
  const [prevPage, setPrevPage] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const handlePageChange = (e) => {
    if (e.currentTarget.title === 'Go to previous page' && prevPage !== null) {
      setPageNumber(pageNumber - 1)
    } else if (e.currentTarget.title === 'Go to next page' && nextPage !== null) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handleRowsPerPageChange = ({ target: { value }}) => {
    setPageNumber(1)
    setRowsPerPage(value)
  }

  const getData = async () => {
    setLoading(true)
    const apiEndpoint = endpoint({
      pageNumber,
      pageSize: rowsPerPage, // API uses different naming
      ...filters
    })
    let res
    try {
      res = await fetch(apiEndpoint, { headers: getHeaders(), credentials: 'include'})
    }
    catch (error) {
      if (error.message === '401') {
        handleUnauthorized()
        console.error('ERROR: ', error)
      }
    }

    if (!res.ok) {
      setError(res.status)
    }
    else {
      const data = await res.json()
      const { count, next, previous, results } = data

      setCount(count)
      setNextPage(next)
      setPrevPage(previous)
      setData(results)
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [pageNumber, filters, rowsPerPage])

  return <Context.Provider value={{
    loading, data, count, pageNumber, nextPage, prevPage, rowsPerPage, error,
    handlePageChange, handleRowsPerPageChange
  }}>
    {children}
  </Context.Provider>
}

export const usePagedData = () => {
  return useContext(Context)
}
