import { Grid, Button } from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'

const RootStyledDiv = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  WebkitBoxPack: 'justify',
  borderTop: '1px gray solid',
  borderBottom: '1px gray solid'
})

const LabelDiv = styled('div')({
  color: 'rgb(62, 62, 60)',
  fontSize: '14px'
})

const DataDiv = styled('div')({
  color: 'rgb(8, 7, 7)',
  fontSize: '16px'
})

const formatPercent = (v) => `${(v * 100).toFixed(2)}%`

const Field = ({ label, value, formatter = (v) => v}) => {

  return <Grid item xs={6} textAlign="center">
    <RootStyledDiv>
      <LabelDiv>{label}</LabelDiv>
      <DataDiv>{formatter(value)}</DataDiv>
    </RootStyledDiv>
  </Grid>
}

const FunderDetails = ({ funder }) => {

  return (
    <Grid container spacing={3}>
      <Field label="Key" value={funder.key} />
      <Field label="Status" value={funder.status} />
      <Field label="Purchase Premium" value={funder.purchase_premium}
        formatter={formatPercent} />
      <Field label="Clawback 1" value={funder.clawback_1}
        formatter={formatPercent} />
      <Field label="Clawback 2" value={funder.clawback_2}
        formatter={formatPercent} />
      <Field label="Clawback 3" value={funder.clawback_3}
        formatter={formatPercent} />
    </Grid>
  )
}

export default FunderDetails
