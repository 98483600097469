import { transactionReportPath, oglReportPath, portfolioReportPath } from '../utils/constant';

export async function getTransactionReport() {
  let filename = '';
  const output = await fetch(transactionReportPath(), {
    headers: {
      accept: 'text/csv'
    },
    credentials: 'include'
  })
    .then((res) => {
      console.log('Response Headers: ', res.headers);
      // eslint-disable-next-line prefer-destructuring
      // filename = res.headers.get('Content-Disposition').split(';')[1].split('=')[1];
      filename = `Transactional_${new Date().getFullYear()}_${
        new Date().getMonth() + 1
      }_${new Date().getDate()}_BLOOM_1.csv`;
      return res.blob();
    })
    .then((blob) => {
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = file;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  return output;
}

export async function getOGLReport() {
  let filename = '';
  const output = await fetch(oglReportPath(), {
    headers: {
      accept: 'text/csv'
    },
    credentials: 'include'
  })
    .then((res) => {
      console.log('Response Headers: ', res.headers);
      // eslint-disable-next-line prefer-destructuring
      // filename = res.headers.get('Content-Disposition').split(';')[1].split('=')[1];
      filename = 'BLOOM.OGL';
      return res.blob();
    })
    .then((blob) => {
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = file;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return output;
}

export async function getPortfolioReport() {
  let filename = '';
  const output = await fetch(portfolioReportPath(), {
    headers: {
      accept: 'text/csv'
    },
    credentials: 'include'
  })
    .then((res) => {
      console.log('Response Headers: ', res.headers);
      // eslint-disable-next-line prefer-destructuring
      // filename = res.headers.get('Content-Disposition').split(';')[1].split('=')[1];
      filename = `SF_Daily_Portfolio_Update_${new Date().getFullYear()}_${
        new Date().getMonth() + 1
      }_${new Date().getDate()}.csv`;
      return res.blob();
    })
    .then((blob) => {
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = file;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return output;
}
