import { Grid } from '@material-ui/core'
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { funderNameFromKey, productNameFromKey } from '@utils/mapping'
import { fDate } from '@utils/formatTime'
import { fCurrency } from '@utils/formatNumber'
import { populatePurchasePrice } from '@controllers/FunderTransferController'

const RootStyledDiv = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  WebkitBoxPack: 'justify',
  borderTop: '1px gray solid',
  borderBottom: '1px gray solid'
})

const LabelDiv = styled('div')({
  color: 'rgb(62, 62, 60)',
  fontSize: '14px'
})

const DataDiv = styled('div')({
  color: 'rgb(8, 7, 7)',
  fontSize: '16px'
})

// const formatPercent = (v) => `${(v * 100).toFixed(2)}%`

const Field = ({ label, value, formatter = (v) => v}) => {

  return <Grid item xs={6} textAlign="center">
    <RootStyledDiv>
      <LabelDiv>{label}</LabelDiv>
      <DataDiv>{formatter(value)}</DataDiv>
    </RootStyledDiv>
  </Grid>
}

const FunderDetails = ({ funderTransfer }) => {
  populatePurchasePrice(funderTransfer)
  return (
    <Grid container spacing={3}>
      <Field label="Previous Funder" value={funderNameFromKey(funderTransfer.previous_funder_key)} />
      <Field label="New Funder" value={funderNameFromKey(funderTransfer.current_funder_key)} />
      <Field label="Product" value={productNameFromKey(funderTransfer.product_key)} />
      <Field label="File Number" value={funderTransfer.loan_account_file_number} />
      <Field label="Created By" value={funderTransfer.created_by} />
      <Field label="Created Date" value={fDate(funderTransfer.created_at)} />
      <Field label="Last Updated Date" value={fDate(funderTransfer.updated_at)} />
      <Field label="Total Value" value={fCurrency(funderTransfer.total_value)} />
      <Field label="Purchase Price" value={fCurrency(funderTransfer.purchase_price)} />
      <Field label="Posted At" value={fDate(funderTransfer.posted_at)} />
    </Grid>
  )
}

export default FunderDetails
