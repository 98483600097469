import { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Page from '@components/Page.js'
import FunderTransferDetails from '@components/_dashboard/funders/FunderTransferDetails'
import PostFunderTransfer from '@components/_dashboard/funders/PostFunderTransfer'
import * as funderController from '@controllers/FunderTransferController'

const FunderTransferPage = () => {
  const {id} = useParams()
  // control states
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [funderTransfer, setFunderTransfer] = useState()

  const getFunderTransfer = async () => {
    const res = await funderController.getFunderTransfer(id)

    if (!res.ok) {
      setError(res.status)
    }
    else {
      const funderTransfer = await res.json()
      setFunderTransfer(funderTransfer)
    }
    setLoading(false)
  }


  // initial load
  useEffect(() => {
    getFunderTransfer()
  }, [])

  return <Page title={`Funder Transfer #${id} | LMS`}>
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Funder Transfer</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading &&
            <Typography>Loading ....</Typography>
          }
          {error &&
            <Typography variant="error-text">Error: {error}</Typography>
          }


          {!loading && funderTransfer && <>
            <FunderTransferDetails funderTransfer={funderTransfer} />
            {!funderTransfer.posted_at && <Box sx={{ pt: 3 }}>
              <PostFunderTransfer id={funderTransfer.id} />
            </Box> }
          </>}

        </Grid>
      </Grid>
    </Container>
  </Page>
}

export default FunderTransferPage
