import { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography } from '@material-ui/core'

import Page from '@components/Page.js'
import FundingSourcesList from '@components/_dashboard/funding-source/FundingSourcesList'
import * as fundingSourceController from '@controllers/FundingSourceController'

const FundingSourcesPage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [fundingSources, setFundingSources] = useState(true)

  const getFundingSources = async () => {
    const res = await fundingSourceController.getAllFundingSources()

    if (!res.ok) {
      setError(res.status)
    } else {
      const { results } = await res.json()
      setFundingSources(results)
    }

    setLoading(false)
  }

  useEffect(() => {
    getFundingSources()
  }, [])

  return (
    <Page title="Funding Sources | LMS">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Funding Sources</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loading && <Typography>Loading ....</Typography>}
            {error && (
              <Typography variant="error-text">Error: {error}</Typography>
            )}

            {!(loading || error) && (
              <FundingSourcesList fundingSources={fundingSources} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default FundingSourcesPage
