import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date) {
  if (!date) return ''
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  if (!date) return ''
  return moment(date).format('DD MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  if (!date) return ''
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateWithoutTimeZone(date) {
  const d = new Date(date);
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
  return format(d, 'dd MMM yyyy HH:mm');
}
