import React, { useState } from "react"
import { Button, TextField, Box, CircularProgress, MenuItem } from '@mui/material'
import Label from "@components/Label";


const transactionFilterTypes = [
    {
        name: 'created_by',
        label: 'Created By'
    },
    {
        name: 'not_created_by',
        label: 'Not Created By'
    },
    {
        name: 'file_number',
        label: 'File Number'
    },
    {
        name: 'posted_on',
        label: 'Posted On Date'
    },
    {
        name: 'funder',
        label: 'Funder Name/Key'
    },
    {
        name: 'product',
        label: 'Product Name/Key'
    },
    {
        name: 'funding_source',
        label: 'Funding Source Key/Institution'
    },
    {
        name: 'posting_date_before',
        label: 'Posted Before'
    },
    {
        name: 'posting_date_after',
        label: 'Posted After'
    },
    {
        name: 'transaction_type',
        label: 'Transaction Type'
    }
]

export default function TransactionFilter({
    handleSearch,
    handleCSVDownload,
    isLoading,
    isDownloading
}) {
    const [selectKey, useSelectKey] = useState([])
    const [selectValue, useSelectValue] = useState('') 
    const [filters, setFilters] = useState([])

    const handleSelectKeyChange = (event) => {
        useSelectKey(event.target.value)
    }

    const handleSelectValueChange = (event) => {
        useSelectValue(event.target.value)
    }

    const addFilter = ({ key, value }) => {
        const newFilter = { key, value }
        setFilters([...filters, newFilter])
        clearCurrentFilters()
    }
   
    const removeFilter = (index) => {
       const newFilters = filters.filter((_, i) => i !== index)
       setFilters(newFilters)
    }

    const clearCurrentFilters = () => {
        useSelectKey('')
        useSelectValue('')
    }
   
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
                sx={{ display: 'flex', gap: 1 }}
            >
                <TextField
                    select 
                    label='Filter By'
                    style={{ minWidth: '200px' }}
                    value={selectKey}
                    defaultValue=""
                    onChange={handleSelectKeyChange}
                >
                    {transactionFilterTypes.map(((item, i) => {
                        return (
                            <MenuItem key={`${item}${i}`} value={item.name}>
                                {item.label}
                            </MenuItem>
                        )
                    }))}
                </TextField>
                <TextField
                    label="Search Term"
                    value={selectValue}
                    onChange={handleSelectValueChange}
                />
                <Button 
                    style={{maxWidth: '150px', maxHeight: '60px'}} 
                    variant="outlined" 
                    size="small" 
                    color="primary" 
                    onClick={() => addFilter({ key: selectKey, value: selectValue })}>
                    Add Filter
                </Button>
                <Button 
                    style={{maxWidth: '150px', maxHeight: '60px'}} 
                    variant="contained" 
                    size="small" 
                    color="primary" 
                    disabled={isLoading}
                    onClick={() => handleSearch(filters)}>
                    Refetch Transactions
                    {isLoading && 
                        <CircularProgress   
                            size={24}
                            sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                            }} />
                    }
                </Button>
                <Button  
                    disabled={isDownloading}
                    style={{maxWidth: '150px', maxHeight: '60px'}} 
                    variant="contained" 
                    size="small" 
                    color="primary" 
                    onClick={() => { handleCSVDownload(filters) }}>
                    Download CSV
                    {isDownloading && 
                        <CircularProgress   
                            size={24}
                            sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                            }} />
                        }
                </Button>
            </Box>
            <Box sx={{ display: 'flex' }}>
                {filters.map((filter, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Label color="secondary">{`${filter.key} : ${filter.value}`}</Label>
                        <Button variant="text" size="small" color="primary" onClick={() => removeFilter(index)}>
                        X
                        </Button>
                    </Box>
                ))}
            </Box>
      </Box>
    )
}